<template>
  <!-- <div class="repairWatchId"> -->
  <!-- <body> -->
  <!-- <div id="repairWatchId"> -->
  <v-app class="repairWatchPage" id="repairWatchPage">
    <!-- <section light > -->
    <!-- <v-layout justify-center class="my-12" v-scrollanimationx> -->
    <!-- <v-row class="justify-center my-8">
            <v-col cols="10" class="text-left">
              <h1 class="display-1 iceBlue mx-12">{{content.title}}</h1>
            </v-col>
    </v-row>-->
    <!-- </v-layout> -->
    <!-- </section> -->
    <section>
      <v-layout justify-center class="my-5">
        <v-row>
          <h1 class="display-1 iceBlue mx-12">{{ content.title }}</h1>
        </v-row>
      </v-layout>
    </section>
    <!-- <section>
          <v-container grid-list-xl>
            <v-layout column align-center justify-center class="white--text">
              <v-row>
                <v-col>
                  <div class="pa-5 headline" v-html="content.shortIntroContent"></div>
                </v-col>
              </v-row>
            </v-layout>
            <v-layout justify-center>
             <center>
              <img
                class="image800Width"
                src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2Frepir_watch.jpeg?alt=media&token=13830851-8337-4d86-8134-2372b95628c3"
              />
            </center>
          </v-layout>
          </v-container>
    </section>-->
    <section>
      <v-parallax
        src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2Frepir_watch.jpeg?alt=media&token=13830851-8337-4d86-8134-2372b95628c3"
        height="500"
        id="halfopacity"
      >
        <!-- style="opacity: 0.6;" -->
        <div
          class="pa-5 headline shadowWhite"
          v-html="content.shortIntroContent"
        ></div>
        <!-- <v-layout column align-center justify-center>
              <div class="display-2 white--text mb-4 text-center">{{content.feedback1}}</div>

        </v-layout>-->
      </v-parallax>
    </section>
    <!-- <section class="lighter-section">
          <v-layout justify-center class="my-12" v-scrollanimationx>
                          <h1 class="display-1 iceBlue mx-12">{{content.repirWatch}}</h1>

          </v-layout>
    </section>-->
    <section class="p10 lighter-section">
      <v-layout row wrap justify-center class="my-12">
        <!-- <h1>{{content.tip}}</h1> -->
        <h1 class="display-1 iceBlue mx-12">{{ content.tip }}</h1>
      </v-layout>
      <v-layout justify-center class="mt-12 mb-3">
        <!-- <div class="textbox-bigger text-left">
          <v-list>
            <v-list-item
              v-for="(oneTip, i) in this.content.tips"
              :key="i"
              @click="tempVar = 1"
            >
              <v-list-item-content>
                <div class="title" v-text="oneTip"></div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div> -->
        <!-- <v-list-item-title class="title" v-text="oneTip"></v-list-item-title> -->
        <!-- <div class="textbox-bigger text-left">
          <v-list>
            <v-list-item
              v-for="(oneTip, i) in this.content.tips"
              :key="i"
              @click="tempVar = 1"
            >
              <v-list-item-content>
                <div class="title" v-text="oneTip"></div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div> -->
        <v-row>
          <v-col
            cols="12"
            md="3"
            lg="3"
            class="pa-0"
            align-self="center"
            v-for="(oneTip, i) in this.content.repairServices"
            :key="i"
          >
          <div :style="{ backgroundColor: oneTip.colour}">
            <!-- <div data-v-9873e810="" class="title">&nbsp; </div> -->
            <br>
            <v-avatar size="200">
              <v-img

                :src="oneTip.img"
                alt="Shop Image"
              />
            </v-avatar>
            <div class="title" v-text="oneTip.name"></div>

            <!-- <div class="title" v-text="oneTip.colour"></div> -->
          </div>
          </v-col>
        </v-row>
      </v-layout>
      <!-- <v-layout justify-center>
            <center>
              <img
                class="image800Width"
                src="https://firebasestorage.https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Ftradisional_clock.jpg?alt=media&token=9d4f8484-6294-4589-82d0-8bcd74e0d1e1
      <v-parallax
        class="pt-10"
        src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2Fwatch_bands3.png?alt=media&token=e816efad-96e3-4d1e-9467-b0a8ea598fab"
        height="500"
      >

      <v-layout column align-center justify-center>
            <div class="display-2 white--text mb-4 text-center">{{content.feedback1}}</div>
      </v-layout>

      </v-parallax>
      -->
    </section>
    <section>
      <v-parallax
        class="pt-10"
        src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2Fwatch_bands3.png?alt=media&token=e816efad-96e3-4d1e-9467-b0a8ea598fab"
        height="500"
      >
      </v-parallax>
    </section>
  </v-app>
  <!-- </div> -->
  <!-- </body> -->
  <!-- </div> -->
</template>

<script>
//   new Vue({
//    el: '#app',
//    vuetify: new Vuetify(),
//    data () {
//      return {
//        title: 'Your Logo'
//      }
//    }
//  })

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'repairWatchPage',
  data () {
    return {
      content: {}
    }
  },
  mounted () {
    if (localStorage.language !== 'en') {
      this.content = {
        title: '名錶維修',
        shortIntro: '',
        shortIntroContent:
          '<p>WeWatch提供專業維修及服務團隊，會致力為客人排解疑難</p><p>當手錶到達門市後，店員會為客人作初步評估及報價，</p><p>然後交由技術人員作誰一步跟進所有維修單據均有清晰標明登記並有相片附加，</p><p>務求令客人安心稱心</p>',
        tip: '修理服務',
        tips: [
          '1. 整錶檢查',
          '2. 維修保養',
          '3. 翻新打磨',
          '4. 洗油',
          '5. 走時故障',
          '6. 手錶進水',
          '7. ​更換錶帶'
        ],
        repairServices: [
          {
            name: '整錶檢查',
            colour: '#755248',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fmaintain_watch.jpg?alt=media&token=210a75e6-d38a-4d6b-a7e1-423d4eac0902'
          },
          {
            name: '維修保養',
            colour: 'rgb(85 97 75)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Frepair_watch.jpg?alt=media&token=bd2bad6c-e7b2-4d54-9ec9-0c84f0b5ff19'
          },
          {
            name: '翻新打磨',
            colour: 'rgb(66 92 99)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fdetails_inside.jpg?alt=media&token=0e1c55cb-52a3-4ed8-8256-d5268982f527'
          },
          {
            name: '洗油',
            colour: 'rgb(150 101 60)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Foil_watch.jpg?alt=media&token=b2386f6a-ae8e-4c48-a64f-145bc969c8cd'
          },
          {
            name: '走時故障',
            colour: 'rgb(66 92 99)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fadjust_time.jpg?alt=media&token=a030e61e-25a7-4621-85b2-742217474e87'
          },
          {
            name: '手錶進水',
            colour: 'rgb(150 101 60)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Ftradisional_clock.jpg?alt=media&token=9d4f8484-6294-4589-82d0-8bcd74e0d1e1'
          },
          {
            name: '​訂製錶帶',
            colour: 'rgb(85 97 75)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Forder_strap.jpg?alt=media&token=6d2a3e1f-4688-43eb-a1b1-68ff8ae73e31'
          },
          {
            name: '​更換錶帶',
            colour: '#755248',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fchange_watch_strap.jpg?alt=media&token=6ee3fc36-ad2b-4e65-952f-bc9796205980'
          }
        ]
      }
    } else {
      this.content = {
        title: 'Watch Repair',
        shortIntro: '',
        shortIntroContent:
          "<p>WeWatch have a professional repair team to provide repirement service and solve your watch's problems</p><p>When the watch recieved at the store, we will make a preliminary evaluation and quotation for you,</p><p>Then, we hand over the watch to the technical staff who will follow up all maintenance </p><p>and report a clearly document attached with photos,</p><p>Please feel free about the maintenance processes</p>",
        tip: 'Repair service',
        tips: [
          '1. Basic Checking',
          '2. Repair and maintenance',
          '3. Polish',
          '4. Overhaul',
          '5. Malfunction Timing',
          '6. Water Damage',
          '7. ​Change Watchband'
        ],
        repairServices: [
          {
            name: 'Basic Checking',
            colour: '#755248',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fmaintain_watch.jpg?alt=media&token=210a75e6-d38a-4d6b-a7e1-423d4eac0902'
          },
          {
            name: 'Repair and maintenance',
            colour: 'rgb(85 97 75)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Frepair_watch.jpg?alt=media&token=bd2bad6c-e7b2-4d54-9ec9-0c84f0b5ff19'
          },
          {
            name: 'Polish',
            colour: 'rgb(66 92 99)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fdetails_inside.jpg?alt=media&token=0e1c55cb-52a3-4ed8-8256-d5268982f527'
          },
          {
            name: 'Overhaul',
            colour: 'rgb(150 101 60)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Foil_watch.jpg?alt=media&token=b2386f6a-ae8e-4c48-a64f-145bc969c8cd'
          },
          {
            name: 'Malfunction Timing',
            colour: 'rgb(66 92 99)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fadjust_time.jpg?alt=media&token=a030e61e-25a7-4621-85b2-742217474e87'
          },
          {
            name: 'Water Damage',
            colour: 'rgb(150 101 60)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Ftradisional_clock.jpg?alt=media&token=9d4f8484-6294-4589-82d0-8bcd74e0d1e1'
          },
          {
            name: 'watch strap',
            colour: 'rgb(85 97 75)',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Forder_strap.jpg?alt=media&token=6d2a3e1f-4688-43eb-a1b1-68ff8ae73e31'
          },
          {
            name: 'Watchband',
            colour: '#755248',
            img: 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fchange_watch_strap.jpg?alt=media&token=6ee3fc36-ad2b-4e65-952f-bc9796205980'
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
/*
8d230f
1e434c
9b4f0f
c99e10
 */
/* h1 {
  color: white;
} */
/*
p {
  color: white;
}

v-img {
  height: 50%;
}

ol {
  color: white;
}

li {
  color: white;
}

body {
  color: white;
} */

.textbox {
  width: 400px;
}

.textbox-bigger {
  width: 80%;
}
.shadowBlue {
  text-shadow: 2px 2px rgb(0, 119, 103);
}

.shadowWhite {
  text-shadow: 2px 2px rgb(100 100 100);
}

.v-list.v-sheet {
  background: inherit;
}
</style>
<style>
#halfopacity .v-parallax__image-container {
  opacity: 0.6;
}
</style>
